/* eslint-disable react/prop-types */
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Button,
  Switch,
  Popover,
  notification,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { projectCreationConstants, commonConstants } from "../../constants";
import {
  projectCreationHelpers,
  spreadConfiguratorHelpers,
} from "../../helpers";
import { isAnyParameterChangedInProjectForSequenceGeneration } from "../../helpers/project-creation";
import {
  SUBMIT_PROJECT_SAGA,
  UPDATE_PROJECT_SAGA,
} from "../../redux/project/types";

const CreateProjectPopup = (props) => {
  let { isModalVisible, setIsModalVisible, selectedProjectEdit } = props;
  const { t } = useTranslation();
  const [projectForm] = Form.useForm();
  const [pipeParameterForm] = Form.useForm();
  const [spreadConfigForm] = Form.useForm();
  const dispatch = useDispatch();
  const reducerList = useSelector((state) => state);

  const [currentTab, setCurrentTab] = useState(
    projectCreationConstants.projectConstants.addProjectFirstForm
  );

  const [, setDisableSequenceEndA] = useState(false);
  const [, setDisableSequenceEndB] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const initialPipeValues = {
    nominalPipeLength: selectedProjectEdit?.nominalPipeLength,
    nominalODofPipe: selectedProjectEdit?.nominalOD,
    targetNumberOfPipes: selectedProjectEdit?.targetNumberOfPipes,
    targetPipelineLength: selectedProjectEdit?.targetPipelineLength,
    resampleSize: selectedProjectEdit?.resampleSize || 360,
    sixOClockExclusion: selectedProjectEdit?.exclusion6OClock,
    weldSeamExclusion: selectedProjectEdit?.weldSeamExclusion,
    hiLoThreshold: selectedProjectEdit?.hiLoThreshold,
    pipeMaterial: selectedProjectEdit?.pipeMaterial,
    pipeType: selectedProjectEdit?.pipeType || "Single",
    pipeRotation: selectedProjectEdit?.pipeRotation || "Any",
    fitQuality: selectedProjectEdit?.fitQuality || "Optimal",
    allowPipeTranslation: selectedProjectEdit?.allowPipeTranslation,
    duplicateSinglePipeEnd: selectedProjectEdit?.duplicateSinglePipeEnd,
  };

  const initialProjectValue = {
    location: selectedProjectEdit?.location,
    contractNumber: selectedProjectEdit?.contractNumber,
    projectName: selectedProjectEdit?.projectName,
    projectNumber: selectedProjectEdit?.projectNumber,
    projectManagerName: selectedProjectEdit?.projectManagerName,
    technicianName: selectedProjectEdit?.technicianName,
    customerName: selectedProjectEdit?.customerName,
    projectManagerEmail: selectedProjectEdit?.projectManagerEmail,
    technicianEmail: selectedProjectEdit?.technicianEmail,
    customerEmail: selectedProjectEdit?.customerEmail,
    startDate: selectedProjectEdit?.startDate
      ? moment(selectedProjectEdit?.startDate)
      : "",
    endDate: selectedProjectEdit?.endDate
      ? moment(selectedProjectEdit?.endDate)
      : "",
  };

  const initialSpreadValues = {
    pipelineTypes: "Unconstraint",
    sequenceEndA: "None",
  };

  const handleCancel = () => {
    projectForm.resetFields();
    pipeParameterForm.resetFields();
    spreadConfigForm.resetFields();
    setIsModalVisible(false);
  };

  const submitProjectFormData = () => {
    if (selectedProjectEdit?.sequenceGenerationStatus === "Started") {
      Modal.error({
        title: t("addProject.sequenceStarted"),
        content: t("addProject.errorsequenceGenerationStatus"),
        okText: "Ok",
        width: 500,
      });
      return;
    }
    if (selectedProjectEdit?.sequenceGenerationStatus === "InProgress") {
      Modal.error({
        title: t("addProject.sequenceInProgress"),
        content: t("addProject.errorsequenceGenerationStatus"),
        okText: "Ok",
        width: 500,
        className: "sequenceInProgress",
      });
      return;
    }
    projectForm
      .validateFields()
      .then(() => {
        setCurrentTab(
          projectCreationConstants.projectConstants.addProjectSecondForm
        );
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const submitPipeParameterFormData = () => {
    pipeParameterForm
      .validateFields()
      .then(() => {
        setCurrentTab(
          projectCreationConstants.projectConstants.addProjectThirdForm
        );
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const handleClickSubmit = () => {
    spreadConfigForm
      .validateFields()
      .then((value) => {
        let projectFormData = projectForm.getFieldValue();
        let pipeParameterFormData = pipeParameterForm.getFieldValue();
        if (pipeParameterFormData.allowPipeTranslation === undefined) {
          pipeParameterFormData.allowPipeTranslation = true;
        }
        let projectPayload = {
          ...projectFormData,
          ...pipeParameterFormData,
          spreadTargetNumberOfPipesInSpread: value?.targetNumberOfPipesInSpread,
          spreadTargetLengthOfSpread: value?.targetLengthOfSpread,
          spreadPipelineTypes: value?.pipelineTypes,
          spreadSequenceEndA: value?.sequenceEndA,
          spreadSequenceEndB: value?.sequenceEndB,
          startDate: projectFormData.startDate
            ? moment(projectFormData.startDate).format("YYYY-MM-DD")
            : null,
          endDate: projectFormData.endDate
            ? moment(projectFormData.endDate).format("YYYY-MM-DD")
            : null,
        };

        let mappedPayload =
          projectCreationHelpers.mapLocalStateToApiRequest(projectPayload);

        dispatch({ type: SUBMIT_PROJECT_SAGA, payload: mappedPayload });
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const updateProject = () => {
    pipeParameterForm
      .validateFields()
      .then(() => {
        let projectFormData = projectForm.getFieldValue();
        let pipeData = pipeParameterForm.getFieldValue();

        let shouldWarningPopupVisible =
          isAnyParameterChangedInProjectForSequenceGeneration(
            selectedProjectEdit,
            {
              ...projectFormData,
              ...pipeData,
            }
          );

        if (
          shouldWarningPopupVisible &&
          selectedProjectEdit?.sequenceGenerationStatus === "Completed"
        ) {
          Modal.confirm({
            title: t("addProject.warningTextForProjectParameterChange"),
            okText: <span style={{ color: "black" }}>{t("common.yes")}</span>,
            cancelText: t("common.no"),
            width: 650,
            className: "custom-approve-modal",
            onOk: handleClickUpdate,
          });
        } else {
          handleClickUpdate();
        }
      })
      .catch(() => {});
  };

  const handleClickUpdate = () => {
    setIsUpdating(true);
    pipeParameterForm
      .validateFields()
      .then((values) => {
        let projectFormData = projectForm.getFieldValue();
        let projectPayload = {
          ...projectFormData,
          ...values,
          startDate: projectFormData.startDate
            ? moment(projectFormData.startDate).format("YYYY-MM-DD")
            : null,
          endDate: projectFormData.endDate
            ? moment(projectFormData.endDate).format("YYYY-MM-DD")
            : null,
        };

        let mappedPayload =
          projectCreationHelpers.mapLocalStateToApiRequest(projectPayload);

        dispatch({ type: UPDATE_PROJECT_SAGA, payload: mappedPayload });
      })
      .catch(() => {})
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleNominalPipeLengthChange = (value) => {
    const targetNumberOfPipes = pipeParameterForm.getFieldValue(
      "targetNumberOfPipes"
    );
    const calculatedTargetPipelineLength = value * targetNumberOfPipes || 0;

    pipeParameterForm.setFieldsValue({
      targetPipelineLength: calculatedTargetPipelineLength,
    });
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const handleTargetNumberOfPipes = (value) => {
    const nominalPipeLength =
      pipeParameterForm.getFieldValue("nominalPipeLength");
    const calculatedTargetPipelineLength = nominalPipeLength * value || 0;

    pipeParameterForm.setFieldsValue({
      targetPipelineLength: calculatedTargetPipelineLength,
    });
  };

  const handleTargetNumberOfPipesInSpread = (value) => {
    const nominalPipeLength =
      pipeParameterForm.getFieldValue("nominalPipeLength");
    const calculatedTargetSpreadLength = nominalPipeLength * value || 0;

    const targetNumberOfPipes = parseInt(
      pipeParameterForm.getFieldValue("targetNumberOfPipes")
    );

    if (parseInt(value) > targetNumberOfPipes) {
      setIsSubmitDisabled(true);
      notification.error({
        message: "Error",
        description: `The provided value (${value}) exceeds the target number of pipes (${targetNumberOfPipes}).`,
      });
    } else {
      setIsSubmitDisabled(false);
    }

    spreadConfigForm.setFieldsValue({
      targetLengthOfSpread: calculatedTargetSpreadLength,
    });
  };

  const handlePipelineTypeChange = (value) => {
    if (value === "Unconstraint" || value === "Continue") {
      spreadConfigForm.setFieldsValue({
        sequenceEndA: "None",
        sequenceEndB: "None",
      });
      setDisableSequenceEndA(true);
      setDisableSequenceEndB(true);
    } else {
      setDisableSequenceEndA(false);
      setDisableSequenceEndB(false);
    }
  };

  let sequenceOptionsList = [];

  return (
    <>
      <Modal
        width="60vw"
        className="quality-modal"
        title={
          selectedProjectEdit?.id
            ? currentTab ==
              projectCreationConstants.projectConstants.addProjectThirdForm
              ? ""
              : t("addProject.updateProject")
            : currentTab ==
              projectCreationConstants.projectConstants.addProjectThirdForm
            ? t("addProject.createFirstSpread")
            : t("addProject.createProject")
        }
        centered={false}
        open={isModalVisible}
        maskClosable={false}
        footer={
          <>
            {selectedProjectEdit?.id &&
            currentTab ==
              projectCreationConstants.projectConstants.addProjectThirdForm ? (
              ""
            ) : (
              <Button key="cancel" type="secondary" onClick={handleCancel}>
                {t("common.cancelButtonText")}
              </Button>
            )}

            {!selectedProjectEdit?.id ? (
              currentTab ===
                projectCreationConstants.projectConstants
                  .addProjectSecondForm ||
              currentTab ===
                projectCreationConstants.projectConstants
                  .addProjectThirdForm ? (
                <Button
                  key="back"
                  type="primary"
                  onClick={() =>
                    setCurrentTab(
                      currentTab ===
                        projectCreationConstants.projectConstants
                          .addProjectSecondForm
                        ? projectCreationConstants.projectConstants
                            .addProjectFirstForm
                        : projectCreationConstants.projectConstants
                            .addProjectSecondForm
                    )
                  }
                >
                  {t("common.backButtonText")}
                </Button>
              ) : (
                ""
              )
            ) : (
              currentTab ===
                projectCreationConstants.projectConstants
                  .addProjectSecondForm && (
                <Button
                  key="back"
                  type="primary"
                  onClick={() =>
                    setCurrentTab(
                      projectCreationConstants.projectConstants
                        .addProjectFirstForm
                    )
                  }
                >
                  {t("common.backButtonText")}
                </Button>
              )
            )}

            {selectedProjectEdit?.id ? (
              currentTab ===
              projectCreationConstants.projectConstants.addProjectFirstForm ? (
                <Button
                  key="next"
                  type="primary"
                  onClick={() => submitProjectFormData()}
                >
                  {t("common.nextButtonText")}
                </Button>
              ) : (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    updateProject();
                  }}
                  disabled={
                    isUpdating ||
                    reducerList?.projectReducer?.isProjectSubmitting
                  }
                >
                  {t("common.submit")}
                </Button>
              )
            ) : currentTab ===
              projectCreationConstants.projectConstants.addProjectFirstForm ? (
              <Button
                key="next"
                type="primary"
                onClick={() => submitProjectFormData()}
              >
                {t("common.nextButtonText")}
              </Button>
            ) : currentTab ==
              projectCreationConstants.projectConstants.addProjectSecondForm ? (
              <Button
                key="next"
                type="primary"
                onClick={() => submitPipeParameterFormData()}
              >
                {t("common.nextButtonText")}{" "}
              </Button>
            ) : (
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  handleClickSubmit();
                }}
                disabled={
                  reducerList?.projectReducer?.isProjectSubmitting ||
                  isSubmitDisabled
                }
              >
                {reducerList?.projectReducer?.isProjectSubmitting
                  ? t(`common.submitting`)
                  : selectedProjectEdit?.id
                  ? ""
                  : t(`common.submit`)}{" "}
              </Button>
            )}
          </>
        }
      >
        {currentTab ==
          projectCreationConstants.projectConstants.addProjectFirstForm && (
          <div>
            <Form
              form={projectForm}
              name="basic"
              layout="vertical"
              initialValues={initialProjectValue}
              autoComplete="off"
            >
              <Row gutter={12}>
                {Object.keys(projectCreationHelpers.projectCreationForm).map(
                  (fieldName) => {
                    const { type, label, placeholder, rules, tooltip } =
                      projectCreationHelpers.projectCreationForm[fieldName];

                    if (type == commonConstants.inputTypes.input) {
                      return (
                        <Col key={fieldName} lg={8}>
                          <Form.Item
                            key={fieldName}
                            label={
                              <span>
                                <Popover
                                  content={t(`${tooltip}`)}
                                  placement="topLeft"
                                  trigger="hover"
                                >
                                  {t(`${label}`)}
                                </Popover>
                              </span>
                            }
                            name={fieldName}
                            rules={rules?.map((rule) => {
                              return {
                                ...rule,
                                message: t(`${rule.message}`),
                              };
                            })}
                          >
                            <Input
                              type={type}
                              size="large"
                              maxLength={
                                commonConstants.commonConstant
                                  .maxFormFieldLength
                              }
                              placeholder={t(`${placeholder}`)}
                              disabled={
                                selectedProjectEdit?.id &&
                                fieldName === "projectNumber"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Item>
                        </Col>
                      );
                    } else if (type == commonConstants.inputTypes.date) {
                      return (
                        <Col key={fieldName} lg={8}>
                          <Form.Item
                            key={fieldName}
                            label={
                              <span>
                                <Popover
                                  content={t(`${tooltip}`)}
                                  placement="topLeft"
                                  trigger="hover"
                                >
                                  {t(`${label}`)}
                                </Popover>
                              </span>
                            }
                            name={fieldName}
                            rules={rules?.map((rule) =>
                              rule.isValidator
                                ? rule
                                : { ...rule, message: t(`${rule.message}`) }
                            )}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              showTime={false}
                              disabledDate={(currentDate) =>
                                fieldName === "endDate" &&
                                projectForm.getFieldValue("startDate")
                                  ? currentDate.isBefore(
                                      moment(
                                        projectForm.getFieldValue("startDate")
                                      )
                                    )
                                  : false
                              }
                              onChange={() => {
                                if (fieldName === "startDate") {
                                  projectForm.setFieldsValue({
                                    endDate: undefined,
                                  });
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
            </Form>
          </div>
        )}
        {currentTab ==
          projectCreationConstants.projectConstants.addProjectSecondForm && (
          <div>
            <Form
              form={pipeParameterForm}
              name="basic"
              layout="vertical"
              initialValues={initialPipeValues}
              autoComplete="off"
            >
              <Row gutter={12}>
                {Object.keys(projectCreationHelpers.pipeParametersForm).map(
                  (fieldName) => {
                    const {
                      type,
                      label,
                      placeholder,
                      rules,
                      options,
                      tooltip,
                    } = projectCreationHelpers.pipeParametersForm[fieldName];

                    if (
                      type == commonConstants.inputTypes.input ||
                      type == commonConstants.inputTypes.number
                    ) {
                      return (
                        <Col key={fieldName} lg={8}>
                          <Form.Item
                            key={fieldName}
                            label={
                              <span>
                                <Popover
                                  content={t(`${tooltip}`)}
                                  placement="topLeft"
                                  trigger="hover"
                                >
                                  {t(`${label}`)}
                                </Popover>
                              </span>
                            }
                            name={fieldName}
                            rules={rules?.map((rule) =>
                              rule.isValidator
                                ? rule
                                : { ...rule, message: t(`${rule.message}`) }
                            )}
                          >
                            <Input
                              type={type}
                              size="large"
                              maxLength={
                                commonConstants.commonConstant
                                  .maxFormFieldLength
                              }
                              placeholder={t(`${placeholder}`)}
                              className="no-spin-buttons"
                              onChange={(e) => {
                                if (fieldName === "nominalPipeLength") {
                                  handleNominalPipeLengthChange(e.target.value);
                                } else if (
                                  fieldName === "targetNumberOfPipes"
                                ) {
                                  handleTargetNumberOfPipes(e.target.value);
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    } else if (type == commonConstants.inputTypes.select) {
                      return (
                        <Col key={fieldName} lg={8}>
                          <Form.Item
                            key={fieldName}
                            label={
                              <span>
                                <Popover
                                  content={t(`${tooltip}`)}
                                  placement="topLeft"
                                  trigger="hover"
                                >
                                  {t(`${label}`)}
                                </Popover>
                              </span>
                            }
                            name={fieldName}
                            rules={rules?.map((rule) => {
                              return {
                                ...rule,
                                message: t(`${rule.message}`),
                              };
                            })}
                          >
                            <Select
                              size="default"
                              placeholder={t(`${placeholder}`)}
                            >
                              {options?.map((ite) => {
                                return (
                                  <Select.Option
                                    key={ite.value}
                                    value={ite.value}
                                  >
                                    {ite.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    } else if (type == commonConstants.inputTypes.switch) {
                      return (
                        <Col key={fieldName} lg={8}>
                          <Form.Item
                            key={fieldName}
                            label={
                              <span>
                                <Popover
                                  content={t(`${tooltip}`)}
                                  placement="topLeft"
                                  trigger="hover"
                                >
                                  {t(`${label}`)}
                                </Popover>
                              </span>
                            }
                            name={fieldName}
                            rules={rules?.map((rule) => {
                              return {
                                ...rule,
                                message: t(`${rule.message}`),
                              };
                            })}
                            valuePropName="checked"
                          >
                            <Switch size="default" defaultChecked={true} />
                          </Form.Item>
                        </Col>
                      );
                    }
                  }
                )}
              </Row>
            </Form>
          </div>
        )}
        {currentTab ==
          projectCreationConstants.projectConstants.addProjectThirdForm &&
          !selectedProjectEdit?.id && (
            <div>
              <Form
                form={spreadConfigForm}
                name="basic"
                layout="vertical"
                autoComplete="off"
                initialValues={initialSpreadValues}
                className="firstspread"
              >
                <Row gutter={12}>
                  {Object.keys(
                    spreadConfiguratorHelpers.spreadConfigurationForm({
                      sequenceList: sequenceOptionsList,
                    })
                  ).map((fieldName) => {
                    const { type, label, placeholder, rules, options } =
                      spreadConfiguratorHelpers.spreadConfigurationForm({
                        sequenceList: sequenceOptionsList,
                      })?.[fieldName];
                    if (
                      type == commonConstants.inputTypes.input ||
                      type == commonConstants.inputTypes.number
                    ) {
                      return (
                        <Col key={fieldName} lg={12}>
                          <Form.Item
                            key={fieldName}
                            label={t(`${label}`)}
                            name={fieldName}
                            rules={rules}
                          >
                            <Input
                              type={type}
                              size="large"
                              maxLength={
                                commonConstants.commonConstant
                                  .maxFormFieldLength
                              }
                              placeholder={t(`${placeholder}`)}
                              className="no-spin-buttons"
                              onChange={(e) => {
                                if (
                                  fieldName === "targetNumberOfPipesInSpread"
                                ) {
                                  handleTargetNumberOfPipesInSpread(
                                    e.target.value
                                  );
                                } //todo
                              }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    } else if (type == commonConstants.inputTypes.select) {
                      return (
                        <Col key={fieldName} lg={12}>
                          <Form.Item
                            key={fieldName}
                            label={t(`${label}`)}
                            name={fieldName}
                            rules={rules}
                          >
                            <Select
                              className="select-drop"
                              bordered={false}
                              size="default"
                              placeholder={t(`${placeholder}`)}
                              onChange={(value) =>
                                handlePipelineTypeChange(value)
                              }
                              disabled={true}
                            >
                              {options?.map((ite) => {
                                return (
                                  <Select.Option
                                    key={ite.value}
                                    value={ite.value}
                                  >
                                    {ite.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Form>
            </div>
          )}
      </Modal>
    </>
  );
};

export default CreateProjectPopup;
