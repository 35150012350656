/* eslint-disable array-callback-return */
import { CloseOutlined, ReloadOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Button, Tooltip, notification } from "antd";
import moment from "moment";

import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import SearchModal from "../components/Search";
import CreateProject from "../components/createProject";
import CreateProjectPopup from "../components/projectCreation/projectCreationPopup";
import { roleBasedAccessControlConstants, commonConstants } from "../constants";
import { projectListingHelper, rabcHelpers } from "../helpers";
import { SET_CONFIGURATION_LISTS } from "../redux/configuration/types";
import { SET_SELECTED_PROJECT } from "../redux/project/types";
import "../global-styles/antd-custom.scss";

const ProjectListHeader = (props) => {
  const { reloadData, projectListData, setProjectList, handlerClearSearch } =
    props;
  const { t } = useTranslation();
  const reducerList = useSelector((state) => state);
  const dispatch = useDispatch();
  const carouselRef = useRef(null);
  const [searchText, onSearch] = useState("");
  const [searchNotFound, setSearchNotFound] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getSelectedProjectId = (id, selectedProject) => {
    if (selectedProject) {
      dispatch({
        type: SET_SELECTED_PROJECT,
        payload: selectedProject,
      });
      dispatch({
        type: SET_CONFIGURATION_LISTS,
        payload: [],
      });
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      let [project] = projectListData?.filter((ite) => ite.id === id);
      if (project) {
        dispatch({
          type: SET_SELECTED_PROJECT,
          payload: project,
        });

        if (reducerList?.projectReducer?.selectedProject?.id != id) {
          dispatch({
            type: SET_CONFIGURATION_LISTS,
            payload: [],
          });
        }
      }
    }
  };

  useEffect(() => {
    getSelectedProjectId(reducerList?.projectReducer?.selectedProject?.id);
  }, [
    projectListData,
    reducerList?.projectReducer?.selectedProject?.id,
    dispatch,
  ]);

  const onCancel = () => {
    setSearchNotFound("");
  };

  const openCreateProject = () => {
    setIsModalVisible(true);
  };

  let isDoesUserHavePermissionToEditProject = rabcHelpers.hasUserHasRole({
    roleName:
      reducerList?.auth?.userDetails?.signInUserSession?.accessToken?.payload?.[
        "cognito:groups"
      ]?.filter((ite) =>
        roleBasedAccessControlConstants.pipeFitterRoles.includes(ite)
      )?.[0] || "",
    scope: roleBasedAccessControlConstants.scopes.project.edit,
  });

  const handleSearch = (searchTerm) => {
    onSearch(searchTerm);
  };

  const handleClickSearch = ({ callBack }) => {
    if (!searchText) {
      notification.warn({
        message: "Please enter project name or project number",
      });

      return;
    }

    const filteredProjects = reducerList.projectReducer.projectList?.filter(
      (project) =>
        project.projectName.toLowerCase().includes(searchText) ||
        project.projectNumber.toLowerCase().includes(searchText)
    );

    if (filteredProjects.length > 0) {
      setProjectList(filteredProjects);

      dispatch({
        type: SET_SELECTED_PROJECT,
        payload: {},
      });

      dispatch({
        type: SET_CONFIGURATION_LISTS,
        payload: [],
      });

      if (carouselRef.current) {
        carouselRef.current.goTo(0);
      }

      callBack();
    } else {
      notification.error({
        message: "No Project Found with " + searchText,
      });
    }
  };

  return (
    <>
      <div className="d-flex pb-2 align-items-center justify-content-between">
        <h1 className="project-title ">Projects</h1>
        <div className="search-container d-flex">
          {searchText !== "" && searchNotFound === false && (
            <div className="top-bar-input d-flex justify-content-between align-items-center me-3">
              <span>{searchText}</span>{" "}
              <a
                className="export-link"
                onClick={() => {
                  onSearch("");
                  handlerClearSearch();
                }}
              >
                <CloseOutlined />
              </a>{" "}
            </div>
          )}
          <SearchModal
            onSearch={(e) => handleSearch(e.target.value.toLowerCase())}
            searchText={searchText}
            setValue={(val) => onSearch(val)}
            onCancel={onCancel}
            handleClickSearch={handleClickSearch}
            projectNumbers={projectListData}
          />

          <Tooltip placement="topLeft" title="Reload">
            <Button
              className="refresh ms-3"
              shape="circle"
              onClick={() => {
                reloadData();
              }}
            >
              <ReloadOutlined />
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="d-flex mb-3">
        <div className="flex-auto">
          <CreateProject />
        </div>

        {projectListData?.length > 0 && (
          <div className="flex-fill overflow-auto">
            <div className="flexslider h-100">
              <Carousel
                ref={carouselRef}
                pagination={false}
                breakPoints={commonConstants.carouselConfig}
              >
                {projectListData.map((data, i) => {
                  return (
                    <div className="float-start slides-divs slides" key={i}>
                      <a
                        id={data.id}
                        onClick={() => {
                          getSelectedProjectId(data.id);
                        }}
                        className={
                          data.id ===
                          reducerList?.projectReducer?.selectedProject?.id
                            ? "slides-items active"
                            : "slides-items"
                        }
                      >
                        <div className="text-center tooltip-parent">
                          {" "}
                          <span
                            className={
                              data.id ===
                              reducerList?.projectReducer?.selectedProject?.id
                                ? "tooltip-text-active"
                                : "tooltip-text"
                            }
                          >
                            {data.projectName.length > 20
                              ? data.projectName
                                  .substring(0, 30)
                                  .concat(
                                    `\n ${data.projectName.substring(
                                      30,
                                      data.projectName.length
                                    )}`
                                  )
                              : ""}
                          </span>
                          <span>
                            {data.projectName.length > 20
                              ? data.projectName.substring(0, 19).concat("...")
                              : data.projectName}
                          </span>
                        </div>
                        <div className="text-center tooltip-parent">
                          {" "}
                          <span>
                            {data.projectNumber.length > 20
                              ? data.projectNumber
                                  .substring(0, 19)
                                  .concat("...")
                              : data.projectNumber}
                          </span>
                          <span
                            className={
                              data.id ===
                              reducerList?.projectReducer?.selectedProject?.id
                                ? "tooltip-text-active-bottom"
                                : "tooltip-text-bottom"
                            }
                          >
                            {data.projectNumber.length > 20
                              ? data.projectNumber
                                  .substring(0, 30)
                                  .concat(
                                    `\n ${data.projectNumber.substring(
                                      30,
                                      data.projectNumber.length
                                    )}`
                                  )
                              : ""}
                          </span>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        )}
      </div>

      <div className="custom-line mb-3" />

      <div className="project-detail-bar">
        <div className="project-detail-bar-items h-100 mb-3">
          {reducerList?.projectReducer?.selectedProject?.id && (
            <>
              {isDoesUserHavePermissionToEditProject && (
                <div
                  className="project-detail-edit"
                  onClick={() => {
                    openCreateProject();
                  }}
                >
                  <EditOutlined className="icon-20" />
                </div>
              )}

              <div className="flex-fill overflow-auto">
                <div className="flexslider-bt flexslider-small h-100">
                  <Carousel
                    pagination={false}
                    breakPoints={commonConstants.carouselConfig}
                  >
                    {projectListingHelper.displayFields?.map((project) => {
                      if (
                        project.value === "startDate" ||
                        project.value === "endDate"
                      ) {
                        const dateValue =
                          reducerList?.projectReducer?.selectedProject[
                            project.value
                          ];
                        const formattedDate = dateValue
                          ? moment(dateValue).format("YYYY-MM-DD")
                          : "-";
                        return (
                          <Col key={project.value} span={24}>
                            <div className="project-details-wrapper text-center">
                              <p className="fw-medium title">
                                {t(`${project.name}`)}
                              </p>
                              <div className="d-flex edit-session justify-content-center mb-2">
                                <p className="mb-0"> {formattedDate}</p>
                              </div>
                            </div>
                          </Col>
                        );
                      } else {
                        const fieldValue =
                          reducerList?.projectReducer?.selectedProject[
                            project.value
                          ] || "-";
                        return (
                          <Col key={project.value} span={24}>
                            <div className="project-details-wrapper text-center">
                              <p className="fw-medium title">
                                {t(`${project.name}`)}
                              </p>
                              <div className="d-flex edit-session justify-content-center mb-4">
                                <div className="text-center tooltip-display-parent">
                                  <p className="mb-0">
                                    {" "}
                                    {fieldValue.length > 19
                                      ? fieldValue
                                          .substring(0, 15)
                                          .concat("...")
                                      : fieldValue}
                                  </p>
                                  <span className="tooltip-display-text">
                                    {fieldValue.length > 15
                                      ? fieldValue
                                          .substring(0, 30)
                                          .concat(
                                            `\n ${fieldValue.substring(
                                              30,
                                              fieldValue.length
                                            )}`
                                          )
                                      : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isModalVisible && (
        <>
          <CreateProjectPopup
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            selectedProjectEdit={reducerList?.projectReducer?.selectedProject}
          />
        </>
      )}
    </>
  );
};

export default ProjectListHeader;
